.mapWrapper {
  width: 100%;
  z-index: 0;

  .map {
    width: 100%;
    height: calc((1920px / 12) * 2);
    z-index: -5;
    // filter: grayscale(2);

    @media all and (max-width:700px) {
      height: calc(((100vw - 96px) / 4) * 3);
    }

    // @media all and (max-width: 1600px) {
    //   height: 550px;
    // }
    // @media all and (max-width: 1200px) {
    //   height: 500px;
    // }
    // @media all and (max-width: 800px) {
    //   height: 400px;
    // }
  }

  iframe{
    width: 100%;
    height: 100%;
    border: none;
    // filter: grayscale(1);
  }

  .markerWrapper {

    padding: 10px 17px;
    // border: 1px solid red;
    display: flex;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-left: -17px;
    margin-top: -17px;


    .marker {
      width: 14px;
      height: 14px;
      border-radius: 100px;
      background: black;
      position: relative;

      .marker__field {
        display: absolute;
        opacity: 0;
        height: auto;
        width: 130px;
        margin-top: -56px;
        margin-left: -52px;
        padding: 8px;
        background: white;
        z-index: 10;
        font-size: 1.2rem;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
        line-height: 1.4;
        transition-duration: 0.3;
      }
    }

    &:hover {
      .marker {
        .marker__field {
          opacity: 1;
        }
      }
    }

  }
}
.grid {
  // margin-right: 64px;
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    width: calc(100% + 32px);

    @media all and (max-width: 700px) {
      // width: calc(200% + 32px - (200% / 12));
      // width: calc(200vw - ((200vw / 12) * 1));
      margin-left: 0px;
      width: 100%;
      // width: calc(200vw - 96px);
      // margin-left: 32px;
      // margin-left: -32px;
      // margin-left: 32px;
      // width: 100%;
      // padding-left: 32px;
    }

    .col-border {
      align-items: flex-start;

      &.col-border-dark {
        box-shadow: inset 0px 0px 0px 0.5px rgb(220, 220, 220);
      }

      &.col-border-white {
        box-shadow: inset 0px 0px 0px 0.5px rgba(100, 100, 100, 1);
      }

      &.col-border-borderless {
        box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 0);
      }

      &.col-fill-dark {
        background: black;
        color: white;
      }

      &.col-fill-white {
        background: white;
        color: black;
      }

      &.col-fill-transparent {
        background: rgba(0, 0, 0, 0);
      }

      &.col-height-default {
        min-height: 220px;
      }

      &.col-height-auto {
        min-height: auto;
      }

      &.col-height-1 {
        min-height: calc((1920px / 12) * 1);
      }

      &.col-height-2 {
        min-height: calc((1920px / 12) * 2);
      }

      &.col-height-3 {
        min-height: calc((1920px / 12) * 3);
      }

      &.col-height-4 {
        min-height: calc((1920px / 12) * 4);
      }

      &.col-height-5 {
        min-height: calc((1920px / 12) * 5);
      }

      &.col-height-6 {
        min-height: calc((1920px / 12) * 6);
      }

      &.col-height-8 {
        min-height: calc((1920px / 12) * 8);
      }

      &.col-height-12 {
        min-height: calc((1920px / 12) * 12);
      }

      &.col-height-220 {
        min-height: 220px;
      }

      &.col-height-440 {
        min-height: 440px;
      }

      @media all and (max-width: 1900px) {
        &.col-height-1 {
          min-height: calc((100vw / 12) * 1);
        }

        &.col-height-2 {
          min-height: calc((100vw / 12) * 2);
        }

        &.col-height-3 {
          min-height: calc((100vw / 12) * 3);
        }

        &.col-height-4 {
          min-height: calc((100vw / 12) * 4);
        }

        &.col-height-5 {
          min-height: calc((100vw / 12) * 5);
        }

        &.col-height-6 {
          min-height: calc((100vw / 12) * 6);
        }

        &.col-height-8 {
          min-height: calc((100vw / 12) * 8);
        }

        &.col-height-12 {
          min-height: calc((100vw / 12) * 12);
        }
      }
    }

    .col {
      &.col-offset-1 {
        margin-left: calc((100% / 12) * 1);
      }

      &.col-offset-2 {
        margin-left: calc((100% / 12) * 2);
      }

      &.col-offset-4 {
        margin-left: calc((100% / 12) * 4);
      }
      
      &.col-offset-7 {
        margin-left: calc((100% / 12) * 7);
      }

      &.col-offset-8 {
        margin-left: calc((100% / 12) * 8);
      }

      &.col-offset-9 {
        margin-left: calc((100% / 12) * 9);
      }

      &.col-offset-10 {
        margin-left: calc((100% / 12) * 10);
      }

      &.col-1 {
        width: calc((100% / 12) * 1);
      }

      &.col-2 {
        width: calc((100% / 12) * 2);
      }

      &.col-3 {
        width: calc((100% / 12) * 3);
      }

      &.col-4 {
        width: calc((100% / 12) * 4);
      }

      &.col-5 {
        width: calc((100% / 12) * 5);
      }

      &.col-6 {
        width: calc((100% / 12) * 6);
      }

      &.col-8 {
        width: calc((100% / 12) * 8);
      }

      &.col-10 {
        width: calc((100% / 12) * 10);
      }

      &.col-12 {
        width: calc((100% / 12) * 12);
      }

      &.col-20 {
        width: calc((100% / 10) * 2);
      }

      @media all and (max-width: 1600px) {
        &.col-md-offset-1 {
          margin-left: calc((100% / 12) * 1);
        }

        &.col-md-offset-2 {
          margin-left: calc((100% / 12) * 2);
        }

        &.col-md-offset-3 {
          margin-left: calc((100% / 12) * 3);
        }

        &.col-md-offset-4 {
          margin-left: calc((100% / 12) * 4);
        }

        &.col-md-offset-8 {
          margin-left: calc((100% / 12) * 8);
        }

        &.col-md-offset-9 {
          margin-left: calc((100% / 12) * 9);
        }

        &.col-md-offset-10 {
          margin-left: calc((100% / 12) * 10);
        }

        &.col-md-1 {
          width: calc((100% / 12) * 1);
        }

        &.col-md-2 {
          width: calc((100% / 12) * 2);
        }

        &.col-md-3 {
          width: calc((100% / 12) * 3);
        }

        &.col-md-4 {
          width: calc((100% / 12) * 4);
        }

        &.col-md-5 {
          width: calc((100% / 12) * 5);
        }

        &.col-md-6 {
          width: calc((100% / 12) * 6);
        }

        &.col-md-8 {
          width: calc((100% / 12) * 8);
        }

        &.col-md-10 {
          width: calc((100% / 12) * 10);
        }

        &.col-md-12 {
          width: calc((100% / 12) * 12);
        }

        &.col-md-20 {
          width: calc((100% / 10) * 2);
        }
      }

      @media all and (max-width: 1200px) {
        &.col-sm-1 {
          width: calc((100% / 12) * 1);
        }

        &.col-sm-2 {
          width: calc((100% / 12) * 2);
        }

        &.col-sm-3 {
          width: calc((100% / 12) * 3);
        }

        &.col-sm-4 {
          width: calc((100% / 12) * 4);
        }

        &.col-sm-5 {
          width: calc((100% / 12) * 5);
        }

        &.col-sm-6 {
          width: calc((100% / 12) * 6);
        }

        &.col-sm-8 {
          width: calc((100% / 12) * 8);
        }

        &.col-sm-10 {
          width: calc((100% / 12) * 10);
        }

        &.col-sm-12 {
          width: calc((100% / 12) * 12);
        }

        &.col-md-20 {
          width: calc((100% / 10) * 2);
        }
      }

      @media all and (max-width: 700px) {
        &.col-xs-offset-0 {
          margin-left: 0px;
        }

        &.col-xs-offset-1 {
          margin-left: calc((100% / 4) * 1);
        }

        &.col-xs-offset-3 {
          margin-left: calc((100% / 4) * 3);
        }

        // &.col-offset-2 {
        //   margin-left: calc((100% / 8) * 2);
        // }

        // &.col-offset-4 {
        //   margin-left: calc((100% / 8) * 4);
        // }

        // &.col-offset-8 {
        //   margin-left: calc((100% / 8) * 8);
        // }

        // &.col-offset-9 {
        //   margin-left: calc((100% / 8) * 9);
        // }

        // &.col-offset-10 {
        //   margin-left: calc((100% / 8) * 10);
        // }

        &.col-1 {
          width: calc(((100% - 96px) / 8) * 1);
        }

        &.col-2 {
          // width: calc((100% / 8) * 2)
          width: calc(((100% - 96px) / 8) * 2);
        }

        &.col-3 {
          width: calc((100% / 8) * 3);
        }

        &.col-4 {
          width: calc(((100% - 96px) / 8) * 3);
        }

        &.col-5 {
          width: calc((100% / 8) * 5);
        }

        &.col-6 {
          width: calc((100% / 8) * 6);
        }

        &.col-8 {
          width: calc((100% / 8) * 8);
        }

        &.col-10 {
          width: calc((100% / 8) * 10);
        }

        &.col-12 {
          width: calc((100% / 8) * 12);
        }

        &.col-20 {
          width: calc((100% / 8) * 2);
        }
      }

      // @media all and (max-width: 1200px) {
      //   padding: 0px 16px;

      //   &.col-offset-1,
      //   &.col-offset-2,
      //   &.col-offset-4,
      //   &.col-offset-8,
      //   &.col-offset-9,
      //   &.col-offset-10 {
      //     margin-left: 0px;
      //   }

      //   &.col-2 {
      //     width: calc((100% / 12) * 3)
      //   }

      //   &.col-3 {
      //     width: calc((100% / 12) * 6)
      //   }

      //   &.col-4,
      //   &.col-5,
      //   &.col-6,
      //   &.col-8,
      //   &.col-10,
      //   &.col-12 {
      //     width: calc((100% / 12) * 12)
      //   }
      // }

      // @media all and (max-width: 600px) {
      //   &.col-2 {
      //     width: calc((100% / 12) * 12)
      //   }

      //   &.col-3 {
      //     width: calc((100% / 12) * 12)
      //   }
      // }
    }

    &.row-gap-64 {
      margin-left: -64px;
      width: calc(100% + (2 * 64px));

      .col {
        padding: 0px 64px;
      }
    }

    &.row-gap-32 {
      margin-left: -32px;
      width: calc(100% + (2 * 32px));

      .col {
        padding: 4px 32px;
      }
    }

    &.row-gap-0 {
      margin-left: 0px;
      width: 100%;

      .col {
        padding: 0px 0px;
      }
    }

    @media all and (max-width: 1200px) {
      &.row-gap-64 {
        margin-left: -32px;
        width: calc(100% + (2 * 32px));

        .col {
          padding: 4px 32px;
        }
      }

      &.row-gap-32 {
        margin-left: -16px;
        width: calc(100% + (2 * 16px));

        .col {
          padding: 4px 16px;
        }
      }

      &.row-gap-0 {
        margin-left: 0;
        width: calc(100%);

        .col {
          // padding: 4px 0px;
        }
      }
    }

    @media all and (max-width: 700px) {
      &.row-gap-64 {
        margin-left: -16px;
        width: calc(100% + (2 * 16px));

        .col {
          padding: 4px 16px;
        }
      }

      &.row-gap-32 {
        margin-left: -8px;
        width: calc(100% + (2 * 8px));

        .col {
          padding: 0px 8px;
        }
      }

      &.row-gap-0 {
        margin-left: 0;
        width: calc(100%);

        .col {
          padding: 0px 0px;
          width: 100%;
        }
      }
    }
  }
}

section {
  .col {
    // border: 3px solid red !important;
  }
}

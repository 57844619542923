.smooth-scrollbar-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;

  @media all and (max-width: 700px) {
    width: 200vw;
  }

  top: 0;
  overflow: hidden;
}

.parallax {
  display: flex;
}
.team-member {
  @media all and (max-width:700px) {
    padding-bottom: 128px;
  }

  .standard-block {
    padding: 36px 42px;
    width: 100%;

    @media all and (max-width:1600px) {
      padding: 32px;
    }

    @media all and (max-width:1400px) {
      padding: 28px;
    }

    @media all and (max-width:1200px) {
      padding: 24px;
    }

    @media all and (max-width:700px) {
      padding: 12px;
    }

    p {
      font-weight: 300;
      font-size: 1.8rem;
      line-height: 1.45;
      font-family: "franklin-gothic-urw", sans-serif;
      color: #313131;

      @media all and (max-width:1600px) {
        font-size: 1.6rem;
        line-height: 1.35;
      }

      @media all and (max-width:1400px) {
        font-size: 1.5rem;
        line-height: 1.35;
      }
    }
  }


  .col-border {
    display: flex;
    align-items: center;
    transition-duration: 1.6s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);

    &.slowly {
      @media all and (min-width: 701px) {
        transform: translateX(150%);
      }
    }
  }

  .col-sm-12 {
    .col-border.col-height-default {
      min-height: auto !important;
    }
  }
}
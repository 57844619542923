.team_section {
  @media all and (max-width: 700px) {
    padding-top: 25vh !important;
    padding-bottom: 25vh !important;
  }

  .showOnMobile {
    @media all and (max-width: 700px) {
      &.team_img {
        position: absolute;
        width: calc(((100vw - 128px) / 4) * 3);
        right: 42px;
        overflow: hidden;
        top: 0px;
        transform: translateX(calc(-0vw + (100vw / 4))) translateY(-40%);
        z-index: -1;

        .fullImg-block {
          width: calc(100% + 32px)
        }
      }
    }

    @media all and (max-width: 500px) {
      &.team_img {
        transform: translateX(calc(-0vw + (100vw / 4))) translateY(-25%);
      }
    }
  }

  .fullImg-block {
    cursor: pointer;
  }
}

.team__description {
  display: flex !important;
  flex-wrap: nowrap !important;

  .col50 {
    width: 100%;
    flex-shrink: 0;
    position: relative;
  }

  .team_img {
    transition-duration: 1.2s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    width: 100%;

    @media all and (max-width: 700px) {
      transform: translateX(calc(-100vw + (100vw / 4))) translateY(-40%);
      z-index: -1;
      position: absolute;
      opacity: 1;
      width: calc(((100vw - 96px) / 4) * 3);
      overflow: hidden;

      .fullImg-block {
        width: calc(100% + 32px)
      }
    }


    @media all and (max-width: 500px) {
      transform: translateX(calc(-0vw + (100vw / 4))) translateY(-25%);
    }

    &.team_img_open {
      transform: translateX(-200%);
      background-color: rgba(0, 0, 0, 0.25);
      opacity: 1;
      z-index: -1;
      position: absolute;


      @media all and (max-width: 700px) {
        transform: translateX(calc(-100vw + (100vw / 4))) translateY(-40%);
      }

      @media all and (max-width: 500px) {
        transform: translateX(calc(-100vw + (100vw / 4))) translateY(-25%);
      }
    }
  }

  .teamSwiper {
    position: absolute;
    height: auto;
    top: 0;
    width: 100%;
    right: 0px;
    transition-duration: 1.2s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);

    overflow: hidden;
    opacity: 0;
    transform: translateX(100%);

    .team_member {
      .team_member__nav {
        padding-left: 16px;
        display: flex;
        flex-wrap: nowrap;
        font-size: 2.1rem;
        font-weight: 700;
        margin-left: -32px;
        margin-bottom: 24px;

        @media all and (max-width:1900px) {
          font-size: 2rem;
        }

        @media all and (max-width:1600px) {
          font-size: 1.8rem;
        }

        @media all and (max-width:1200px) {
          font-size: 1.6rem;
        }

        @media all and (max-width:800px) {
          font-size: 1.4rem;
        }

        @media all and (max-width:700px) {
          font-size: 1.9rem;
        }

        .leftBtn,
        .rightBtn {
          padding: 8px 16px;
          user-select: none;
          cursor: pointer;

          &.disabled {
            cursor: initial;
            color: #bbb;
          }
        }

        .name {
          padding: 7px 16px;
          margin-left: 1px;
          margin-right: 1px;
        }
      }
    }

    &.teamSwiper--open {
      transform: translateX(-100%);
      opacity: 1;
      position: initial;

      .team-member {
        .col-border.slowly {
          @media all and (min-width: 701px) {
            opacity: 1;
            transform: translateX(0%);
          }
        }
      }
    }
  }
}
.tk-franklin-gothic-urw {
  font-family: "franklin-gothic-urw", sans-serif;
}

.tk-franklin-gothic-ext-comp-urw {
  font-family: "franklin-gothic-ext-comp-urw", sans-serif;
}

.tk-franklin-gothic-urw-cond {
  font-family: "franklin-gothic-urw-cond", sans-serif;
}

.tk-franklin-gothic-urw-comp {
  font-family: "franklin-gothic-urw-comp", sans-serif;
}

.tk-operetta-8 {
  font-family: "operetta-8", serif;
}

.tk-operetta-12 {
  font-family: "operetta-12", serif;
}

.tk-operetta-18 {
  font-family: "operetta-18", serif;
}

.tk-operetta-32 {
  font-family: "operetta-32", serif;
}

.tk-operetta-52 {
  font-family: "operetta-52", serif;
}

.home-page {
  section {
    padding-top: 30vh;
    padding-bottom: 30vh;

    @media all and (max-width: 700px) {
      padding-top: 20vh;
      padding-bottom: 20vh;
    }
  }

  .hideOnDesktop {
    transition-duration: 0.3s;
    opacity: 0;
    pointer-events: none;
  }

  .hideOnMobile {
    @media all and (max-width: 700px) {
      display: none;
    }
  }

  .showOnMobile {
    @media all and (min-width: 701px) {
      display: none;
    }
  }
}
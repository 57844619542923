.offer_section {
  // padding-top: 15vh;
  // padding-bottom: 25vh;
  // margin-top: 25vh;
  z-index: 99;

  .fullImg-block {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .offer__description {
    display: flex !important;
    flex-wrap: nowrap !important;

    .col50 {
      width: 100%;
      flex-shrink: 0;
      position: relative;
    }

    .offerSwiper {
      position: absolute;
      height: auto;
      top: 0;
      width: 100%;
      right: 0;
      transition-duration: 1.2s;
      transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
      opacity: 0;
      transform: translateX(100%);

      &.offerSwiper--open {
        transform: translateX(-100%);
        opacity: 1;
        position: initial;

        .offer_member {
          .col-border.slowly {
            @media all and (min-width: 701px) {
              opacity: 1;
              transform: translateX(0%);
            }
          }
        }
      }

      &.offerSwiperDefault {
        transform: translateX(100%);

        &.offerSwiper--open {
          transform: translateX(0%);
        }
      }
    }
  }
}

.offer_section_mobile {
  .offer_member {
    .offer-mobile-nav {
      .title {
        .standard-block {
          padding: 12px 40% 8px 8px;
          font-family: "operetta-8", serif !important;
          line-height: 1.4;
          font-size: 16px;
          font-weight: 400;
          position: relative;
          width: 100%;

          &:after {
            content: ">";
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 22px;
            font-weight: bold;
            font-family: "franklin-gothic-urw", sans-serif;
          }
        }
      }
    }
  }

  .offer_wrapper {
    .col-border {
      min-height: auto !important;

      &.slowly {
        @media all and (min-width: 701px) {
          transform: translateX(0%);
        }
      }

      .standard-block {
        padding: 14px;
        font-size: 14px !important;
      }

      &.title {
        padding: 0px;

        .standard-block {
          font-size: 13px !important;
        }
      }
    }

    &.offer_wrapper_mobile {
      .title-standard-block {
        padding: 12px;
        padding-left: 0px;
      }
    }
  }
}

.offerSection__description {
  .fullImg-block {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &.offer__description {
    display: flex !important;
    flex-wrap: nowrap !important;

    .col50 {
      width: 100%;
      flex-shrink: 0;
      position: relative;
    }

    .offerSwiper {
      position: absolute;
      height: auto;
      top: 0;
      width: 100%;
      right: 0;
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
      opacity: 0;
      transform: translateX(100%);

      &.offerSwiper--open {
        transform: translateX(-100%);
        opacity: 1;
        // position: initial;

        .offer_member {
          .col-border.slowly {
            @media all and (min-width: 701px) {
              opacity: 1;
              transform: translateX(0%);
            }
          }
        }
      }

      &.offerSwiperDefault {
        transform: translateX(100%);

        &.offerSwiper--open {
          transform: translateX(0%);
        }
      }
    }
  }
}

.offerSection__description,
.offer_section_mobile {

  .offer_member {
    .titleLink {
      min-height: auto !important;

      .standard-block {
        padding: 8px 40% 8px 12px;
        font-family: "operetta-8", serif !important;
        line-height: 1.4;
        font-size: 16px;
        font-weight: 400;
        position: relative;
        width: 100%;

        &:after {
          content: ">";
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 22px;
          font-weight: bold;
          font-family: "franklin-gothic-urw", sans-serif;
        }
      }
    }
  }

  .offer_wrapper {
    .col-border {
      min-height: auto !important;

      &.slowly {
        @media all and (min-width: 701px) {
          transform: translateX(0%);
        }
      }

      .standard-block {
        padding: 14px;
        font-size: 14px !important;
      }

      &.title {
        padding: 0px;
        font-size: 13px !important;
        
        .standard-block {
          font-size: 13px !important;
        }
      }
    }

    &.offer_wrapper_mobile {
      .title-standard-block {
        padding: 12px;
        padding-left: 0px;
      }
    }
  }
}
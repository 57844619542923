.sticky-col-wrapper {
  height: 100%;
  // background: #444;
  transition-duration: 0.2s;

  @media all and (max-width: 700px) {
    height: auto;
  }

  .sticky-col {
    font-size: 2rem;
    color: white;
    // transition-duration: 0.1s;
    // transition-timing-function: ease-in-out;
    // border: 2px solid blue;
    // height: 100%;
    // margin-top: auto;
    // margin-bottom: 0px;
    // transition-duration: 0.1s;
  }

  &.align-center {}
}
.team-hero {
  .subtitle__button {
    margin-top: 76px;

    @media all and (max-width:1600px) {
      margin-top: 64px;
    }

    @media all and (max-width:1200px) {
      margin-top: 42px;
    }
  }

  // .magic-col{
  //   position: relative;
  //   &:after{
  //     position: absolute;
  //     content: '';
  //     background: linear-gradient(to right, rgba(0,0,0,1) 0%, black 49%, black 100%);
  //     width: 76%;
  //     height: 100%;
  //     top: 0px;
  //     left: 24%;
  //     z-index: -999999;
  //   }
    
  //   &.isOpen{
  //     &:after{
  //       background: linear-gradient(to right, rgba(0,0,0,0) 0%, black 49%, black 100%);
  //     }
  //   }
  // }
}
.publications-section {

  .standard-block {
    p {
      &:hover {
        a {
          color: black !important;
          text-decoration: underline;
        }
      }
    }

  }
}


.publications_whiteCol {
  &.offer__description {
    position: relative;
    margin-top: 80px;
    height: auto;

    @media all and (max-width: 700px) {
      margin-top: 0px;
      margin-left: 0px !important;
      padding-left: 0px !important;

      .col-6 {
        width: 50% !important;

        .standard-block {
          padding: 12px !important;
        }
      }
    }




    .offer_member__description {
      height: auto;
      width: 100%;
      top: 0;
      right: 0;
      transition-duration: 1.2s;
      transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);

      @media all and (max-width:700px) {
        padding-bottom: 128px;
      }

      .standard-block {
        padding: 24px 32px;

        @media all and (max-width:1600px) {
          padding: 24px;
        }

        @media all and (max-width:1400px) {
          padding: 20px;
        }

        @media all and (max-width:1200px) {
          padding: 12px;
        }

        p {
          font-weight: 300;
          font-size: 1.8rem;
          line-height: 1.45;
          font-family: "franklin-gothic-urw", sans-serif;
          color: #313131;

          @media all and (max-width:1600px) {
            font-size: 1.6rem;
            line-height: 1.35;
          }

          @media all and (max-width:1400px) {
            font-size: 1.5rem;
            line-height: 1.35;
          }
        }


        p.subtitle {
          font-weight: 500;
          letter-spacing: initial;
          margin-top: 48px;

          @media all and (max-width:1600px) {
            margin-top: 32px;
          }

          @media all and (max-width:1200px) {
            margin-top: 24px;
          }
        }

        p.italic {
          font-style: italic;
        }
      }

      .logo-col {
        align-items: center;
        justify-content: center;
        display: flex;

        @media all and (max-width:1400px) {
          img {
            height: 60px;
          }
        }

        @media all and (max-width:700px) {
          img {
            height: 60px;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }

      .col-border {
        height: auto;
        display: flex;
        align-items: center;
        transition-duration: 1.6s;
        transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
        padding: 8px;
      }

      .vertical {
        display: flex;
        flex-direction: column;
        padding: 0px;

        .col {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // padding: 24px;
        }
      }
    }
  }

  .loadMoreWrapper {
    min-height: 0px !important;
    padding: 0px !important;

    .loadMore {
      padding: 12px;
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 2.1rem;
      cursor: pointer;
      user-select: none;
      box-shadow: inset 0px 0px 0px 0.2px #bebebe;

      &:hover {
        box-shadow: inset 0px 0px 0px 0.5px black;
      }
    }

    &.loadMoreWrapper--allLoaded {
      .loadMore {
        cursor: initial;
        color: #bbb;

        &:hover {
          box-shadow: inset 0px 0px 0px 0.2px #bebebe;
        }
      }
    }
  }
}
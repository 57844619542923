.section-card-overlay {
  position: fixed;
  top: 0;
  left: 100%;
  height: 100vh;
  width: calc(100vw - 48px);
  background: white;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  z-index: 2;
  box-sizing: border-box;

  &.isOpen {
    transition-duration: 0.5s;
    left: 48px;
  }

  .section-card-overlay--inner {
    position: relative;
    width: calc(100vw - 48px);
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    z-index: 3;

    .mobile-fluent-grid-bg {
      position: absolute;
      z-index: 5;
      top: 0px;
      left: 0px;
      width: calc(100% - 48px);
      height: 100%;
    }

    .whiteCols {
      position: relative;
      z-index: 5;
      top: 0px;
      left: 0px;
      width: 100%;
      padding-right: 48px;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 64px;
      overflow: auto;
      overflow-x: hidden;

      .container {
        padding-left: 0px;
        padding-right: 0px;

        .logo {
          width: 100%;
          margin-bottom: 48px;

          img {
            width: 100%;
            filter: invert(2);
          }
        }
      }
    }
  }


  .hideOnCardOpen {
    transition-duration: 0.3s !important;
    opacity: 0 !important;
  }
}

.mobileBtn__Wrapper {
  @media all and (min-width: 701px) {
    display: none;
  }

  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 60px;
  width: 100vw;
  z-index: 5;
  transition-duration: 0.3s;

  &.isHidden {
    opacity: 0;
  }

  .mobileBtn {
    position: fixed;
    width: 100vw;
    height: 60px;
    bottom: 0px;
    left: 0px;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    // transition-timing-function: ease-in-out;

    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.6px;
    font-size: 12px;
    text-transform: uppercase;
  }


  .mobileBtn--first {
    color: black;
    background: white;
    text-align: center;
    padding-left: 24px;

    span {
      margin-left: auto;
      margin-right: 24px;
      color: black;
    }
  }

  .mobileBtn--call {
    span {
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .mobileBtn--third,
  .mobileBtn--second {
    left: 100vw;
    font-weight: bold;
    font-size: 24px;
    color: white;
    background: black;

  }


  .mobileBtn--second {
    width: 100vw;
  }

  .mobileBtn--third {
    border-left: 1px solid #333;
    width: 50vw;
  }

  &.isSecondOpen {

    .mobileBtn {
      transition-duration: 0.3s;
    }

    .mobileBtn--first {
      left: -100vw;
    }

    .mobileBtn--second {
      left: 0px;
    }
  }

  &.isThirdOpen {
    .mobileBtn--second {
      width: 50vw;
    }

    .mobileBtn--third {
      left: 50vw;
    }
  }
}
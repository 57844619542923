@keyframes showBgOnLoad {
  0% {
    background: white;
  }

  99% {
    background: linear-gradient(to right, black, black 50%, white 50%, white);
  }

  100% {
    background: linear-gradient(to right, black, black 50%, white 50%, white);
  }
}

html {
  font-size: 10px;
  font-family: 'franklin-gothic-urw', serif;
  color: black;
  overflow: auto;
  // background: linear-gradient(to right, black, black 50%, white 50%, white);
  overscroll-behavior-y: none;
  overflow-x: hidden;
  max-width: 100%;
  animation: showBgOnLoad 2s normal forwards;
}

@media all and (max-width: 1200px) {
  html {
    font-size: 9px;
  }
}

@media all and (max-width: 700px) {
  html {
    background: black;
    font-size: 8px;

  }
}

body {
  position: relative;
  overflow: hidden;
  overscroll-behavior-y: none;
  transition-duration: 0.1s;
  // background: linear-gradient(to right, black, black 50%, white 50%, white);
  animation: showBgOnLoad 2s normal forwards;
  width: 100%;
}

@media all and (max-width: 700px) {
  body {
    background: black;
  }
}

*,
:before,
:after {
  box-sizing: border-box;
  margin: 0px;
}




a,
.topLinkWrapper {
  text-decoration: none;
  color: rgba(49, 49, 49, 1);

  &:hover {
    color: #F2C98B !important;
  }
}

.toast {
  animation-duration: 0.3s !important;
  font-size: 0.9rem !important;
  border-radius: 4px !important;
  padding: 5px 20px !important;
  min-height: 30px !important;
  color: white !important;
  font-weight: 600 !important;
}

.toasts-container {
  top: 80px !important;
  right: 25px !important;

  .toast {
    font-size: 1.5rem !important;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.overlay {
  transition-delay: 0s;
  transition-duration: 0.2s;
  opacity: 1;
}

.overlay.isActive {
  transition-delay: 0.0s;
  transition-duration: 0.4s;
  backdrop-filter: brightness(0.75);
}

.rellax-wrapper {
  // transition: transform 10s cubic-bezier(0, 1, .5, 1);
}
.collapsible-block {
  width: 100%;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);

  .collapsible-block__title {
    text-align: left;
    padding: 0px 12px 12px 0px;
    user-select: none;
    cursor: pointer;
    position: relative;
    display: flex;

    .title {
      color: white;
      font-family: "operetta-8", serif;
      font-size: 2.1rem;
      width: auto;
      display: block;
      margin-right: auto;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
      line-height: 36px;

      @media all and (max-width:1200px) {
        font-size: 2rem;
        line-height: 24px;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      &:hover {
        font-size: 2.8rem;

        @media all and (max-width:1200px) {
          font-size: 2.4rem;
        }
      }
    }
  }

  .collapsible-block__content {
    padding: 0px;
    margin-bottom: 0px;
    padding-left: 3px;
    padding-right: 8px;
    padding-top: 12px;
    width: 100%;
    color: rgba(255, 255, 255, 0);
    font-weight: 100;
    font-size: 1.8rem;
    line-height: 1.45;
    font-family: "franklin-gothic-urw", sans-serif;
    opacity: 0;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  }

  .ReactCollapse--collapse {
    transition: height 0.7s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);

  }

  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    width: 100vw;
    right: 0;
    height: 100%;
    box-shadow: inset 0px 0px 0px 0.5px rgba(100, 100, 100, 1);
    top: 0;
    background: black;
    z-index: -1;
    opacity: 0;
    transition-duration: 0s;

  }

  &.collapsible-block--open {
    margin-top: 12px;
    padding-top: 24px;
    margin-bottom: 24px;
    position: relative;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);

    &:after {
      opacity: 1;
      transition-duration: 0.7s;
    }

    .collapsible-block__title {
      .title {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 3.5rem;

        @media all and (max-width:1200px) {
          font-size: 2.6rem;
          padding-top: 6px;
          padding-bottom: 0px;
        }

        &:hover {
          // font-size: 3.2rem;
          transform: scale(1);
        }
      }
    }

    .collapsible-block__content {
      padding-bottom: 32px;
      color: rgba(255, 255, 255, 1);
      opacity: 1;
    }
  }
}
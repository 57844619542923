@keyframes slideLeftOnLoad {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0vw);
  }
}

.about_section {
  padding-top: 15vh !important;

  @media all and (max-width: 1920px) {
    padding-top: 12vh !important;
  }

  @media all and (max-width: 700px) {
    padding-top: 10vh !important;
  }

  .col-border.aboutUs__description {
    padding: 32px 24px;
    animation: slideLeftOnLoad 1.2s normal forwards;
    animation-delay: 3.1s;
    -moz-animation-delay: 3.8s;


    @media all and (max-width: 1200px) {
      padding: 24px 18px;
    }
  }
}

@media all and (max-width: 700px) {
  .aboutUs__description {
    min-height: 0px !important;
    height: auto;
    margin-bottom: 128px;

    .standard-block {
      padding: 16px;
    }
  }
}
@keyframes showOnLoad {
  from {
    transform: translateY(-100vh);
    opacity: 0;
  }

  to {
    transform: translateY(-1px);
    opacity: 1;
  }
}

.mobile-fluent-grid-bg {
  display: none;
  width: 100%;
  height: 100vh;

  @media all and (max-width: 700px) {
    display: initial;
  }

  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .grid {
    height: 100vh;

    .row {
      height: 100%;

      .col {
        animation: showOnLoad 1s normal forwards ease-in-out;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          box-shadow: inset 1px 0px 0px 0px rgba(235, 235, 235, 1);
        }

        @media all and (max-width: 700px) {

          &.double-size {
            width: calc(100% / 4);
          }
        }

        &:last-child {
          box-shadow: inset 0px 0px 0px 1px rgba(235, 235, 235, 1);
        }

      }
    }
  }
}
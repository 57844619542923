.contact-section {

  @media all and (max-width:700px) {
    padding-bottom: 0px !important;
  }

  .col-border.contact__info {
    margin-top: 80px;
    padding: 32px 24px;
    background: white;
    height: calc((1920px / 12) * 2);
  }

  .contact__in {
    margin-top: 80px;

    .col-border {
      height: calc((1920px / 12) * 1);
      margin-top: calc((1920px / 12) * 1);

    }

    a {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 100%;
      cursor: pointer;

      &:hover {
        box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
      }
    }
  }

  .contact__top {
    margin-top: 80px;

    .col-border {

      .arrowTopWrapper {
        height: calc((1920px / 12) * 1);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
        }

        .arrowTop {
          font-size: 44px;
          transform: rotate(-90deg);
          font-weight: bold;
        }
      }
    }
  }


  .mobile__contact {
    @media all and (max-width:700px) {

      .contact__info {
        color: white;
        padding: 0px;
        margin-top: 48px;

        p {
          line-height: 1.5;
          font-size: 2rem;
          font-weight: 300;

          @media all and (max-width:500px) {
            line-height: 1.5;
            font-size: 1.35rem;
            font-weight: 300;
          }

          strong {
            color: white;
          }
        }
      }

      .col-xs-offset-1 {
        margin-left: calc(((100vw - 98px) / 4) * 1) !important;
        margin-top: 48px;


        .arrowTopWrapper,
        .col-height-1 {
          height: calc(((100vw - 96px) / 4) * 1) !important;
          min-height: calc(((100vw - 96px) / 4) * 1) !important;
          margin-top: 0px;
          margin-bottom: 24px;

        }
      }

      .arrowTopWrapper {
        &:hover {
          box-shadow: inset 0px 0px 0px 0.5px #646464 !important;
        }
      }

      .contact__in {
        a {
          &:hover {
            box-shadow: inset 0px 0px 0px 0.5px #646464 !important;
          }
        }
      }

      .contact__in,
      .contact__top {
        color: white;

        .col-border {
          &:hover {
            // box-shadow: inset 0px 0px 0px 0.5px #646464 !important;
          }
        }

        .arrowTop {
          font-size: 32px !important;

          @media all and (max-width:500px) {
            font-size: 26px !important;
          }
        }

        img {
          filter: invert(2);
          height: 20px;

          @media all and (max-width:500px) {
            height: 16px;
          }
        }
      }
    }
  }
}
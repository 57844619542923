footer {
  .grid {
    .row {
      .col-border {
        box-shadow: none !important;
        margin-left: 1px;
        width: calc(100% - 3px);
        border-top: 1px solid rgba(180, 180, 180, 0.25);

        &.col-height-default {
          min-height: auto;
        }

        .standard-block {
          p {
            font-size: 1rem;
            font-weight: 300;
            padding-top: 21px;
            padding-bottom: 21px;
          }
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    padding-bottom: 76px;
    padding-top: 12px;
    box-shadow: none !important;
    color: white;
    padding-left: 48px;

    margin-left: 1px;
    width: calc(100vw - 50px);
    border-top: 1px solid rgba(180, 180, 180, 0.25);
    background: black;

    p {
      font-size: 0.9rem;
      font-weight: 300;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}
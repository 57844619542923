.offer_member {
  
  @media all and (max-width:700px) {
    padding-bottom: 128px;
  }

  .standard-block {
    padding: 34px 42px;

    @media all and (max-width:1600px) {
      padding: 32px;
    }

    @media all and (max-width:1400px) {
      padding: 28px;
    }

    @media all and (max-width:1200px) {
      padding: 24px;
    }

    @media all and (max-width:700px) {
      padding: 12px;
    }

    p {
      font-weight: 300;
      font-size: 1.8rem;
      line-height: 1.45;
      font-family: "franklin-gothic-urw", sans-serif;
      color: #313131;

      @media all and (max-width:1600px) {
        font-size: 1.6rem;
        line-height: 1.35;
      }

      @media all and (max-width:1400px) {
        font-size: 1.5rem;
        line-height: 1.35;
      }
    }
  }

  .col-border {
    // background: white !important;
    display: flex;
    align-items: center;
    transition-duration: 1.6s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);

    @media all and (max-width:1200px) {
      padding: 0px;
    }

    &.title {
      min-height: initial;
      font-weight: 700;
      padding: 0px 8px;
      min-height: auto !important;

      @media all and (max-width:1200px) {
        padding: 0px;
      }

      .standard-block {
        font-size: 1.8rem;

        @media all and (max-width:1900px) {
          font-size: 2rem;
        }

        @media all and (max-width:1600px) {
          font-size: 1.8rem;
        }

        @media all and (max-width:1200px) {
          font-size: 1.6rem;
        }

        @media all and (max-width:800px) {
          font-size: 1.4rem;
        }

        @media all and (max-width:700px) {
          font-size: 1.4rem !important;
          margin-left: 1px;
        }
      }
    }

    &.slowly {
      @media all and (min-width: 701px) {
        transform: translateX(150%);
      }
    }
  }


  .col-sm-12 {
    .col-border {
      min-height: auto !important;
    }
  }

}
.preloader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes hidePreloader {
    0% {
      opacity: 1;
    }

    99% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }


  &.isFinished {
    animation: hidePreloader 1s normal forwards;
    opacity: 0;
  }

  &.isHidden {
    display: none;
  }

  .box {
    margin-bottom: 128px;

    .rectsWrapper {
      display: flex;

      @keyframes upAndDown {
        0% {
          transform: translateY(16px)
        }

        5% {
          transform: translateY(16px)
        }

        50% {
          transform: translateY(-16px)
        }

        95% {
          transform: translateY(16px)
        }

        100% {
          transform: translateY(16px)
        }
      }

      @keyframes downAndUp {
        0% {
          transform: translateY(-16px)
        }

        5% {
          transform: translateY(-16px)
        }

        50% {
          transform: translateY(16px)
        }

        95% {
          transform: translateY(-16px)
        }

        100% {
          transform: translateY(-16px)
        }
      }

      .rect {
        margin-left: 10px;
        margin-right: 10px;
        width: 28px;
        height: 38px;
        border: 6px solid black;
        animation-timing-function: ease-in-out;

        &.rect--left {
          animation: upAndDown 2.5s infinite;
        }

        &.rect--right {
          animation: downAndUp 2.5s infinite;
        }
      }
    }

    .counter {
      margin-top: 72px;
      font-size: 1.4rem;
      font-weight: 900;
      text-align: center;
    }
  }
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: calc(1952px);
  margin: auto;
  background-position: center center;
  background-repeat: repeat-y;

  // @media all and (max-width: 1200px) {
  //   padding: 0px 64px;
  // }

  @media all and (max-width: 700px) {
    padding: 0px 48px;
  }

  &.isRaw {
    padding: 0px;
  }
}
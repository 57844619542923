@keyframes showOnLoad {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(-1px);
  }
}

@keyframes showBgOnLoad {
  0% {
    background: white;
  }

  99% {
    background: linear-gradient(to right, black, black 50%, white 50%, white);
  }

  100% {
    background: linear-gradient(to right, black, black 50%, white 50%, white);
  }
}


.fluent-grid {
  position: relative;
  transition-duration: 0.3s;

  &.slideLeft {
    margin-left: -100vw;
    transition-duration: 0.6s;

    // transform: translateX(-100vw);
    // transition-duration: 10.3s;
    transition-timing-function: ease-in-out;
  }

  .fluent-grid-bg {
    position: fixed;
    z-index: 0;
    height: 120vh;
    width: 100vw;

    @media all and (max-width: 700px) {
      width: calc(200vw - 96px);

      .desktop-only {
        display: none;
      }
    }

    left: -0.5px;
    animation: showBgOnLoad 2s normal forwards;

    top: -1px;

    .grid {
      height: 100vh;

      .row {
        height: 100%;

        .col {
          animation: showOnLoad 2s normal forwards ease-in-out;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            box-shadow: inset 1px 0px 0px 0px rgba(35, 35, 35, 1);
          }

          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(11),
          &:nth-child(12) {
            box-shadow: inset 1px 0px 0px 0px rgba(235, 235, 235, 1);
          }


          @media all and (max-width: 700px) {

            &.double-size {
              width: calc(100% / 8);
            }

            &.desktop-only {
              display: none;
            }
          }

          &:last-child {
            box-shadow: inset 0px 0px 0px 1px rgba(235, 235, 235, 1);
          }

        }
      }

    }
  }

  .fluent-grid-content {
    z-index: 1;
    position: relative;
  }
}
.navbar {
  .col.navbar__logo {
    padding-top: 80px;

    img {
      width: 100%;
      min-width: 110px;
    }
  }

  .col.navbar__in {
    margin-top: 80px;

    @media all and (max-width: 700px) {
      display: none;
    }

    .col-border {
      height: 100%;

      &:hover {
        box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1) !important;
      }
    }

    a {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 100%;

      img {
        height: 24px;
        width: 24px;
      }

      @media all and (max-width: 1600px) {
        img {
          height: 18px;
          width: 18px;
        }
      }

      @media all and (max-width: 1200px) {
        img {
          height: 14px;
          width: 14px;
        }
      }
    }
  }
}